// define a mixin object
import {log} from "@/shared/utils";
import {
    getTemplateList,
    getTemplateListArchive,
    getTemplateByID,
    copyTemplateByID,
    archiveTemplateByID,
    restoreTemplateByID,
    getTemplateDescriptionFromTemplateSchemaSettingsCollectionByID,
    getTemplateSchemaSettingsCollectionByID,
    saveTemplateSchemaSettingsCollectionByID,
    getBusinessUnits,
    createNewTemplate,
    saveTemplateByID,
} from "@/shared/dataservice";
import {showDefaultServerErrorMessage} from "@/shared/utils";

export const templateActionsMixin = {
    data() {
        return {
            templateData: {}
        }
    },
    methods: {
        async loadActiveTemplatesList(page = 1) {
            let result = await getTemplateList(page);

            log(["Loaded active template", true, page, result]);

            this.activeTableData = result.data.data;
            this.activeTablePagination = result.data.meta;
        },
        async loadArchiveTemplatesList(page = 1) {
            let result = await getTemplateListArchive(page);

            log(["Loaded archive template", page, result]);

            this.archiveTableData = result.data.data;
            this.archiveTablePagination = result.data.meta;
        },
        async loadTemplateDescriptionByID(TemplateSchemaSettingsCollectionID = -1) {

            log(["Getting template description from schema", TemplateSchemaSettingsCollectionID]);

            let result = await getTemplateDescriptionFromTemplateSchemaSettingsCollectionByID(TemplateSchemaSettingsCollectionID);

            this.templateData = result.data.data;
        },
        async loadTemplateSchemaSettingsCollectionByID(TemplateSchemaSettingsCollectionID = -1){
            log(["Getting template schema by id", TemplateSchemaSettingsCollectionID]);

            let result = await getTemplateSchemaSettingsCollectionByID(TemplateSchemaSettingsCollectionID);

            this.templateData = result.data.data;
        },
        async saveTemplateSchemaSettingsCollectionByID(TemplateSchemaSettingsCollectionID){
            log(["Save template schema settings", this.templateData]);

            let result =  await saveTemplateSchemaSettingsCollectionByID(TemplateSchemaSettingsCollectionID, this.templateData);

            log(["Save template schema settings result", result]);

            if(result.status === 200){
                return true;
            }
            else
            {
                return false;
            }
        },
        async loadTemplateByID(id) {

            log(["Getting template by id", id]);

            let result = await getTemplateByID(id);

            this.templateData = result.data.data;
        },
        async saveTemplate(){
            log(["Save template (General)", this.templateData]);

            if(this.getTemplateID().toString() === "0"){
                return this.addTemplate(this.templateData);
            }
            else
            {
                return this.updateTemplateByID(this.templateData);
            }
        },
        async addTemplate(templateData){
            log(["Adding template", templateData]);

            let result = await createNewTemplate(templateData);

            log(["Adding template result", result]);

            if(result.status === 201){
                return result.data.data.id;
            }
            else
            {
                return false;
            }
        },
        async updateTemplateByID(templateData){
            log(["Update template by ID", templateData.id, templateData]);

            let result =  await saveTemplateByID(templateData.id, templateData);

            log(["Update template by ID result", result]);

            if(result.status === 200){
                return true;
            }
            else
            {
                return false;
            }
        },
        async loadBusinessUnits() {
            let result = await getBusinessUnits();
            log(["Got template business units", result]);
            return result.data.data;
        },

        /**
         * UTILS
         */

        /**
         * TEMPLATE GETTERS
         */
        getTemplateID() {
            return Object.prototype.hasOwnProperty.call(this.templateData, "id") ? this.templateData.id : "";
        },
        getTemplateName() {
            return Object.prototype.hasOwnProperty.call(this.templateData, "name") ? this.templateData.name : "";
        },
        getTemplateProperties(){
            return Object.prototype.hasOwnProperty.call(this.templateData, "properties") ? Object.entries(this.templateData.properties).sort((a, b) => {  return a[1].order - b[1].order; }) : {};
        },
        getTemplateArchived(){
            return Object.prototype.hasOwnProperty.call(this.templateData, "archived") ? this.templateData.archived : false;
        },

        /**
         * TEMPLATE SETTERS
         */
        setTemplateID(id) {
            this.templateData.id = id;
        },
        setTemplateName(name){
            this.templateData.name = name;
        },
        setTemplateBusinessUnitIDs(ids){
            let newIDs = Array.isArray(ids) ? ids : [ids];
            this.templateData.businessUnitIds = newIDs;
        },

        /**
         * TEMPLATE PROPERTY GETTERS
         */
        getTemplatePropertyByAlias(alias){
            return Object.prototype.hasOwnProperty.call(this.templateData.properties, alias) ? this.templateData.properties[alias] : false;
        },

        /**
         * TEMPLATE PROPERTY SETTERS
         */
        setTemplatePropertyLabelByAlias(alias, value){
            if(Object.prototype.hasOwnProperty.call(this.templateData.properties, alias)){
                this.templateData.properties[alias].label = value;
            }
        },
        setTemplatePropertyTooltipByAlias(alias, value){
            if(Object.prototype.hasOwnProperty.call(this.templateData.properties, alias)){
                this.templateData.properties[alias].tooltip = value;
            }
        },
        setTemplatePropertyExcludedByAlias(alias, value){
            if(Object.prototype.hasOwnProperty.call(this.templateData.properties, alias)){
                this.templateData.properties[alias].excluded = value;
            }
        },
        setTemplatePropertyValueByAlias(alias, value){
            if(Object.prototype.hasOwnProperty.call(this.templateData.properties, alias) && Object.prototype.hasOwnProperty.call(this.templateData.properties[alias], "defaultValue")){
                this.templateData.properties[alias].defaultValue.value = value;
            }
        },
        setTemplatePropertyLockedByAlias(alias, value){
            if(Object.prototype.hasOwnProperty.call(this.templateData.properties, alias)){
                this.templateData.properties[alias].locked = value;
            }
        },
        setTemplatePropertyIgnoreOptionsByAlias(alias, value){
            if(Object.prototype.hasOwnProperty.call(this.templateData.properties, alias) && Object.prototype.hasOwnProperty.call(this.templateData.properties[alias], "configuration") && Object.prototype.hasOwnProperty.call(this.templateData.properties[alias].configuration, "data")){
                this.templateData.properties[alias].configuration.data.ignoreOptions = value;
            }
        },
        setTemplatePropertySelectedOptionsByAlias(alias, selectedIDs){
            if(Object.prototype.hasOwnProperty.call(this.templateData.properties, alias) && Object.prototype.hasOwnProperty.call(this.templateData.properties[alias], "configuration") && Object.prototype.hasOwnProperty.call(this.templateData.properties[alias].configuration, "data") && Object.prototype.hasOwnProperty.call(this.templateData.properties[alias].configuration.data, "options")){
                this.templateData.properties[alias].configuration.data.options.forEach(option => {
                    option.selected = selectedIDs.includes(option.id);
                });
            }
        },
        setTemplatePropertySelectedMediaItemOptionsByAlias(alias, selectedMediaItemIDs){
            if(Object.prototype.hasOwnProperty.call(this.templateData.properties, alias) && Object.prototype.hasOwnProperty.call(this.templateData.properties[alias], "configuration") && Object.prototype.hasOwnProperty.call(this.templateData.properties[alias].configuration, "data")){
                let selectedMediaItemsIDArray = [];

                selectedMediaItemIDs.forEach(mediaItemID =>{
                    selectedMediaItemsIDArray.push({ id : mediaItemID });
                });

                this.templateData.properties[alias].configuration.data.options = selectedMediaItemsIDArray;
            }
        },

        /**
         * TEMPLATE SCHEMA
         */
        setTemplateSchemaPropertyLabelByAlias(alias, value){
            if(Object.prototype.hasOwnProperty.call(this.templateData.propertySettings, alias)){
                this.templateData.propertySettings[alias].label = value;
            }
        },
        setTemplateSchemaPropertyTooltipByAlias(alias, value){
            if(Object.prototype.hasOwnProperty.call(this.templateData.propertySettings, alias)){
                this.templateData.propertySettings[alias].tooltip = value;
            }
        },
        getTemplateSchemaProperties(){
            return Object.prototype.hasOwnProperty.call(this.templateData, "propertySettings") ? Object.entries(this.templateData.propertySettings).sort((a, b) => {  return a[1].order - b[1].order; }) : {};
        },


        /**
         * BUSINESS UNITS IDS
         */
        getTemplateBusinessUnitIDs() {
            return Object.prototype.hasOwnProperty.call(this.templateData, "businessUnitIds") ? this.templateData.businessUnitIds[0] : ""; /* This is an array even though only planned to have one result at present */
        },
        parseBusinessUnitsToSelectOptions(businessUnits) {
            return businessUnits.map(function(row) {
                return {
                    value: row.id,
                    text: row.name,
                };
            })
        },

        /**
         * ACTIONS
         */
        async copyTemplate(id, successRouteName) {

            let result = await copyTemplateByID(id);

            log(["Copy Template", id, successRouteName, result]);

            if(result.status === 200){
                this.$router.push( { name: successRouteName, params: { 'id' : result.data.data.id, 'message' : "iscopy" } } );
            }
            else
            {
                showDefaultServerErrorMessage("Copy Template");
            }
        },
        async archiveTemplate(id, successMethodName) {
            let result = await archiveTemplateByID(id);

            log(["Archive Template", id, result]);

            if(result.status === 200){
                this[successMethodName]();
            }
            else
            {
                showDefaultServerErrorMessage("Archive Template");
            }
        },
        async restoreTemplate(id, successMethodName) {
            let result = await restoreTemplateByID(id);

            log(["Restore Template", id, result]);

            if(result.status === 200){
                this[successMethodName]();
            }
            else
            {
                showDefaultServerErrorMessage("Restore Template");
            }
        },
    },
}